<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-invoice-count :data="internal" :title='`Internal`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-invoice-count :data="local" :title='`Local`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-invoice-count :data="corporate" :title='`Corporate`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-invoice-count :data="voids" :title='`Void`' />
          </b-col>
          <b-col
            lg="3"
            md="3"
            cols="3"
          >
            <ecommerce-invoice-count :data="total" :title='`Total`' />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->

      <!--/ Revenue Report Card -->
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getToken, getUserData } from '@/auth/utils'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceInvoiceCount from './EcommerceInvoiceCount.vue'
// import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
// import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
// import EcommerceMeetup from './EcommerceMeetup.vue'
// import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
// import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
// import EcommerceTransactions from './EcommerceTransactions.vue'

const userToken = getToken()
const headers = {
  'X-Token-Access': `Bearer ${userToken}`,
  'Content-Type': 'application/json',
}
const internal = 0
const local = 0
const corporate = 0
const voids = 0
const total = 0
export default {
  components: {
    BRow,
    BCol,
    // EcommerceRevenueReport,
    EcommerceInvoiceCount,
    // EcommerceEarningsChart,
    // EcommerceCompanyTable,
    // EcommerceMeetup,
    // EcommerceBrowserStates,
    // EcommerceGoalOverview,
    // EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      userToken,
      internal,
      local,
      corporate,
      voids,
      total,
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        const userData = getUserData()
        const firstName = userData.FullName.charAt(0).toUpperCase() + userData.FullName.slice(1)
        console.log(firstName)
      })
  },
  mounted() {
      this.getDashboard()
  },
  methods: {
      getBranches() {
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => (
                  { label: elem.Kota, value: elem.BranchID }
                ))
                this.branches = branchesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      async getDashboard() {
        const todaysDate = new Date()
        const yyyy = todaysDate.getFullYear().toString()
        const mm = (todaysDate.getMonth() + 1).toString()
        const dd = todaysDate.getDate().toString()
        const mmChars = mm.split('')
        const ddChars = dd.split('')
        const month = mmChars[1] ? mm : `0${mmChars[0]}`
        const day = ddChars[1] ? dd : `0${ddChars[0]}`
        const fullToday = `${yyyy}-${month}-${day}`
        const aMonthAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate())
        const yyyy2 = aMonthAgo.getFullYear().toString()
        const mm2 = (aMonthAgo.getMonth() + 1).toString()
        const dd2 = aMonthAgo.getDate().toString()
        const mmChars2 = mm2.split('')
        const ddChars2 = dd2.split('')
        const month2 = mmChars2[1] ? mm2 : `0${mmChars2[0]}`
        const day2 = ddChars2[1] ? dd2 : `0${ddChars[0]}`
        const fullOneMonthAgo = `${yyyy2}-${month2}-${day2}`
        const body = {
              DateFrom: fullOneMonthAgo,
              DateTo: fullToday,
          }
          const url = 'https://api.geolims.com/dashboard_service/data'
          const res = await axios
              .post(url, body, { headers })
              .catch(err => {
                // same, log whatever you want here
                console.log(err)
              }).catch(error => {
            console.log(error.toJSON())
          })
          if (res.data.Status === 1) {
            this.internal = res.data.Payload.Invoices.Internal
            this.local = res.data.Payload.Invoices.Local
            this.corporate = res.data.Payload.Invoices.Corporate
            this.voids = res.data.Payload.Invoices.Void
            this.total = res.data.Payload.Invoices.Total
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                constiant: 'danger',
                text: 'Something went wrong',
              },
            })
            }
      },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
